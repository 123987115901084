import { useFetchers } from '@remix-run/react'

const useFetchersIdle = () => {
  /** Returns True if all fetchers are currently idle.
   *
   *  Use this on routes that contain other forms that make use of useFetcher.
   *  This way, you can make your main form disabled if any of the other forms
   *  is currently submitting.
   */
  const fetchers = useFetchers()

  let isIdle = true
  fetchers.forEach((fetcher) => {
    if (fetcher.state !== 'idle') {
      isIdle = false
    }
  })

  return isIdle
}

export default useFetchersIdle
